










































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Component, { mixins } from 'vue-class-component';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import getYear from 'date-fns/getYear';
import debounce from 'lodash/debounce';
import Decimal from 'decimal.js';
import camelcaseKeys from 'camelcase-keys';
import printJS from 'print-js';

import { toasts } from '@/utils/toasts';
import { formatNumber as fn } from '../../../utils/number-formatting';

import EditMaterialsRequisitionNotesLineModal from './EditMaterialsRequisitionNotesLineModal.vue';
import AccountingDataModal from './AccountingDataModal.vue';
import MaterialsRequisitionNoteMixin from './materials-requisition-note-mixin.vue';
import DocumentLogEntriesModal from './DocumentLogEntriesModal.vue';
import AccountingSyncModal from './AccountingSyncModal.vue';

import {
  lookupProduct,
  getAvailableInventories,
  getAvailableSeries,
  getAvailableStock,
  saveDraft,
  getDocumentLogs,
  deleteDraft,
  compareLatestSeries,
  deleteCanceled,
  restoreDocument,
  cancelDocument,
  postDraft,
  getDetails,
  getPdf,
  getOnlyProductWithAvailableStock,
  addPublishedLine,
  deletePublishedLine,
  syncAccounting,
  unsyncAccounting,
  updateDocumentFooter,
} from '../api/materials-requisition-notes-api';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    EditMaterialsRequisitionNotesLineModal,
    DocumentLogEntriesModal,
    AccountingDataModal,
    AccountingSyncModal,
  },
})
export default class extends mixins(MaterialsRequisitionNoteMixin) {
  loading = true;
  dataConsumYear = getYear(new Date());

  backupFooter: any = {};
  isEditingFooter = false;

  get Decimal() {
    return Decimal;
  }

  formatNumber(input, dp) {
    return fn(input, dp);
  }

  printNumCopies = 1;
  printPreviewIframe: HTMLIFrameElement|null = null;

  beforeDestroy() {
    // Clean up the print preview IFRAME, we don't need it anymore
    if (this.printPreviewIframe) {
      this.printPreviewIframe.parentElement!.removeChild(this.printPreviewIframe);
    }
  }

  hoveredRowIndex = -1;

  rowHovered(_: unknown, index: number) {
    this.hoveredRowIndex = index;
  }

  rowUnhovered() {
    this.hoveredRowIndex = -1;
  }

  //
  // Preview modal imag source
  //
  previewModalImageSrc = '/api/mrn/jpeg/_placeholder_';

  //
  // Preview modal events
  //
  async onPreviewModalCancel() {
    if (this.$route.name !== 'MaterialsRequisitionNotes.Details') {
      this.$router.replace({ name: 'MaterialsRequisitionNotes.Details', params: { id: `${this.materialsRequisitionNoteId}` } });
      return;
    }
    await this.initialize();
  }

  async onPreviewModalOk(evt) {
    evt.preventDefault();

    const confirmOutcome = await this.$bvModal.msgBoxConfirm('Salvarea bonului de cosum va duce la procesarea acestuia in gestiune. Dacă mai târziu vor fi identificate erori, acestea trebuie corectate printr-o anulare. Dorești să continui?', {
      centered: true,
      okTitle: 'Da',
      cancelTitle: 'Nu',
    });
    if (!confirmOutcome) {
      return;
    }

    try {
      await postDraft(this.materialsRequisitionNoteId);
    } catch (err) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
      return;
    }

    // We can close the modal now
    this.$bvModal.hide('preview-modal');

    toasts.success('Documentul a fost salvat ca bon de consum!');
    if (this.$route.name !== 'MaterialsRequisitionNotes.Details') {
      this.$router.replace({ name: 'MaterialsRequisitionNotes.Details', params: { id: `${this.materialsRequisitionNoteId}` } });
      return;
    }
    await this.initialize();
  }

  items: Array<any> = [];
  documentLogs: Array<any> = [];

  fields: Array<any> = [
    {
      key: 'idx',
      label: 'Nr. crt.',
      thClass: 'text-center tbl-col-idx',
      tdClass: 'text-center py-2 tbl-col-idx',
    },
    {
      key: 'product',
      label: 'Produs',
      thClass: 'tbl-col-product',
      tdClass: 'py-2 tbl-col-product',
    },
    {
      key: 'measurementUnit',
      label: 'U.M.',
      thClass: 'text-center tbl-col-mu',
      tdClass: 'py-2 text-center tbl-col-mu',
    },
    {
      key: 'requiredQuantity',
      label: 'Cant. necesară',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
    {
      key: 'specificUnitPrice',
      label: 'Preț gest. specific',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
    {
      key: 'requisitionedQuantity',
      label: 'Cant. consumată',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
    {
      key: 'actions',
      label: '',
      thClass: 'text-right tbl-col-actions',
      tdClass: 'text-right tbl-col-actions',
    },
  ];

  publishedFields: Array<any> = [
    {
      key: 'idx',
      label: 'Nr. crt.',
      thClass: 'text-center tbl-col-idx',
      tdClass: 'text-center py-2 tbl-col-idx',
    },
    {
      key: 'product',
      label: 'Produs',
      thClass: 'tbl-col-product-published',
      tdClass: 'py-2 tbl-col-product-published',
    },
    {
      key: 'measurementUnit',
      label: 'U.M.',
      thClass: 'text-center tbl-col-mu',
      tdClass: 'py-2 text-center tbl-col-mu',
    },
    {
      key: 'requiredQuantity',
      label: 'Cant. necesară',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
    {
      key: 'requisitionedQuantity',
      label: 'Cant. consumată',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
    {
      key: 'inventoryUnitPrice',
      label: 'Pret unitar',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
    {
      key: 'inventoryRequisitionValue',
      label: 'Valoare',
      thClass: 'text-right tbl-col-qty-value',
      tdClass: 'py-2 text-right tbl-col-qty-value',
    },
    {
      key: 'actions',
      label: '',
      thClass: 'text-right tbl-col-actions',
      tdClass: 'text-right tbl-col-actions',
    },
  ];

  async onInventoryChange(value) {
    if (value) {
      await this.asyncGetProductsWithAvailableStocks();
    }
  }

  //
  // Quantity event handlers
  //
  onRequisitionedQuantityChanged(value) {
    this.requisitionedQuantity = value;
    if (!this.requiredQuantity) {
      this.requiredQuantity = new Decimal(value).toFixed(3);
    }
  }

  onRequiredQuantityChanged(value) {
    this.requiredQuantity = value;
    this.requisitionedQuantity = new Decimal(value).toFixed(3);
  }

  onProductSelected(value) {
    if (!value) {
      return;
    }

    this.productDescription = value.description;
    this.measurementUnit = value.measurementUnit;

    this.selectedProductPrice = null;
    this.multiselectProductPriceList = [];

    (this.$refs.productDescriptionInputControl as any).focus();
  }

  onProductPriceSelected(value) {
    if (!value) {
      return;
    }

    (this.$refs.requisitionedQuantity as any).focus();
  }

  validateWrapper(validator, value) {
    // Every time we go through this validator, we mark the data as not saved
    if (this.isDraft) {
      this.isSaved = false;
    }

    validator(value);
  }

  dateFormatterDataConsum(value) {
    this.dataConsumValueDatepicker = format(parse(value, 'dd / MM / yyyy', new Date()), 'yyyy-MM-dd');
    return format(parse(value, 'dd / MM / yyyy', new Date()), 'dd / MM / yyyy');
  }

  async onContextDataConsum(ctx) {
    const currentDate = new Date().toISOString().substring(0, 10);

    if (currentDate < ctx.selectedYMD) {
      if (!(await this.$bvModal.msgBoxConfirm('Atenție, data de consum a documentului este ulterioară datei curente', {
        okTitle: 'Continuă salvarea',
        cancelTitle: 'Anulează salvarea',
        centered: true,
        hideHeader: true,
        noCloseOnBackdrop: true,
      }))) {
        await this.onContextDataConsumOk(currentDate);
        return;
      }
      await this.onContextDataConsumOk(ctx.selectedYMD);
    } else {
      await this.onContextDataConsumOk(ctx.selectedYMD);
    }
  }

  async onContextDataConsumOk(value) {
    this.dataConsumValue = format(parse(value, 'yyyy-MM-dd', new Date()), 'dd / MM / yyyy');
    const ctxDate = parse(value, 'yyyy-MM-dd', new Date());

    if (ctxDate.getFullYear() !== this.dataConsumYear) {
      this.documentSeries = null;
      this.dataConsumYear = ctxDate.getFullYear();
      this.documentSeriesOptions = camelcaseKeys(await getAvailableSeries(this.dataConsumYear));

      if (this.documentSeriesOptions.length === 1) {
        [this.documentSeries] = this.documentSeriesOptions;
      }
    }

    await this.asyncGetProductsWithAvailableStocks();
  }

  previewModalImageShown = false;

  // Diplay fields for the document dates
  dataConsumValue = '20 . 12 . 2020';

  //
  // Header-level multiselect fields
  //

  productsWithAvailableStocks: Array<any> = [];

  allInventoryOptions: Array<any> = [];
  inventoryOptions: Array<any> = [];
  isInventoryOptionsLoading = false;

  documentSeriesOptions: Array<any> = [];
  isDocumentSeriesOptionsLoading = false;

  // Computed current user name getter
  get currentUserFullName() {
    return this.$store.state.fullName;
  }

  // Satus
  isSaved = false;

  //
  // Header fields
  //

  materialsRequisitionNoteId: number|null = null;
  statusCode: number = 0;

  get isDraft(): Boolean {
    return this.statusCode === 0;
  }
  get isPublished(): Boolean {
    return this.statusCode === 1;
  }
  get isSynchronized(): Boolean {
    return this.statusCode === 3;
  }
  get isCanceled(): Boolean {
    return this.statusCode === 2;
  }
  get isDocumentCreated() : Boolean {
    return this.materialsRequisitionNoteId !== null;
  }

  // The entire inventory details object
  inventory: any|null = null;

  // The entire series object
  documentSeries: any|null = null;
  documentNumber = '';
  dataConsumValueDatepicker = '2020-12-20';

  //
  // Footer fields
  //
  documentNotes: string|null = null;
  privateNotes: string|null = null;

  releasedBy: string|null = null;
  requisitionedBy: string|null = null;

  async asyncGetProductsWithAvailableStocks() {
    if (!this.inventory) return;

    const result = await getOnlyProductWithAvailableStock({
      inventory: this.inventory?.label,
      documentDate: this.dataConsumValueDatepicker,
    });
    this.productsWithAvailableStocks = result;
  }

  // Row-level multiselect fields

  multiselectProductList: Array<any> = [];
  multiselectProductLoading = false;

  multiselectProductPriceList: Array<any> = [];
  multiselectProductPriceLoading = false;

  // Row fields

  // The entire product details object (productid, label, description, measurement unit, whether it is active)
  selectedProduct: any|null = null;

  // Product price information
  selectedProductPrice: any|null = null;

  // Copied from the product on selection
  productDescription: string|null = null;
  lineNotes: string|null = null;
  // Copied from the product on selection
  measurementUnit: string|null = null;

  // These affect each other
  requiredQuantity: string | null = null;
  requisitionedQuantity: string | null = null;
  // This is actually an object, the key is the VAT percentage
  vatPercentage? = { label: '19 %', key: 0.19 };
  lineValue: string | null = null;
  lineVatValue: string | null = null;

  isLastDocument: boolean = false;

  //
  // Search functions
  //

  async asyncSearchProductPrices() {
    this.multiselectProductPriceLoading = true;

    const outcome = await getAvailableStock({
      lookupDate: format(parse(this.dataConsumValue, 'dd / MM / yyyy', new Date()), 'yyyy-MM-dd'),
      productId: this.selectedProduct.productId,
      inventoryId: this.inventory.inventoryId,
    });

    this.multiselectProductPriceList = camelcaseKeys(outcome.stockEntries);

    this.multiselectProductPriceLoading = false;

    return Promise.resolve();
  }

  async asyncSearchProducts(query: string) {
    if (!query || query.length < 1) {
      return Promise.resolve();
    }

    this.multiselectProductLoading = true;

    this.multiselectProductList = camelcaseKeys(await lookupProduct(query));

    this.multiselectProductLoading = false;

    await this.asyncGetProductsWithAvailableStocks();
    this.markProductsWithNoStock();

    return Promise.resolve();
  }

  markProductsWithNoStock() {
    const nonNullCodProducts = this.productsWithAvailableStocks.map((product) => product.codProdus);
    this.multiselectProductList.forEach((product) => {
      // eslint-disable-next-line no-param-reassign
      product.hasStock = nonNullCodProducts.includes(product.label);
      // eslint-disable-next-line no-param-reassign
      product.availableStock = product.hasStock
        ? this.productsWithAvailableStocks.find((e) => e.codProdus === product.label)?.stoc : 0;

      this.decrementAlreadyUsedStock(product);
    });

    this.multiselectProductList.sort((a, b) => {
      if (a.hasStock && !b.hasStock) return -1;
      if (!a.hasStock && b.hasStock) return 1;
      return 0;
    });
  }

  decrementAlreadyUsedStock(product) {
    if (product.hasStock) {
      const currProductLines = this.items.filter((e) => e.productCode === product.label)?.map((e) => Number(e.requisitionedQuantity));
      const totalReqQuantity = currProductLines !== null ? currProductLines.reduce((acc, curr) => acc + curr, 0) : 0;
      // eslint-disable-next-line no-param-reassign
      product.availableStock -= totalReqQuantity;
      // eslint-disable-next-line no-param-reassign
      product.hasStock = product.availableStock > 0;
    }
  }

  async asyncSearchInventory() {
    if (this.allInventoryOptions.length) {
      this.inventoryOptions = this.allInventoryOptions.filter((o) => o.isUserAssociated);
      return Promise.resolve();
    }

    this.isInventoryOptionsLoading = true;

    this.allInventoryOptions = camelcaseKeys(await getAvailableInventories());
    this.inventoryOptions = this.allInventoryOptions.filter((o) => o.isUserAssociated);

    this.isInventoryOptionsLoading = false;
    return Promise.resolve();
  }

  //
  // Search functions (debounced)
  //

  debouncedSearchProducts = debounce(this.asyncSearchProducts, 75, { maxWait: 150 });
  debouncedSearchInventory = debounce(this.asyncSearchInventory, 75, { maxWait: 150 });
  debouncedSearchProductPrices = debounce(this.asyncSearchProductPrices, 75, { maxWait: 150 });

  //
  // Event handlers
  //

  onInventorySelected(option) {
    if (!option) {
      return;
    }

    if (option.storekeeper && !this.releasedBy) {
      this.releasedBy = option.storekeeper;
    }
  }

  onEditFooter() {
    this.backupFooter = {
      documentNotes: this.documentNotes,
      privateNotes: this.privateNotes,
      releasedBy: this.releasedBy,
      requisitionedBy: this.requisitionedBy,
    };

    this.isEditingFooter = true;
  }

  onCancelFooterEditing() {
    this.documentNotes = this.backupFooter.documentNotes;
    this.privateNotes = this.backupFooter.privateNotes;
    this.releasedBy = this.backupFooter.releasedBy;
    this.requisitionedBy = this.backupFooter.requisitionedBy;

    this.isEditingFooter = false;
  }

  async onSubmitFooterEditing() {
    if (this.backupFooter.documentNotes === this.documentNotes
    && this.backupFooter.privateNotes === this.privateNotes
    && this.backupFooter.releasedBy === this.releasedBy
    && this.backupFooter.requisitionedBy === this.requisitionedBy) {
      this.isEditingFooter = false;
      return;
    }

    const errors: Array<string> = [];
    if (!this.releasedBy) {
      errors.push('Nume gestionar necompletat');
    }

    if (!this.requisitionedBy) {
      errors.push('Nume primitor necompletat');
    }

    if (errors.length) {
      this.showErrorsToast(errors, 'mrnEditFooter');
      return;
    }

    try {
      await updateDocumentFooter(this.materialsRequisitionNoteId, {
        documentNotes: this.documentNotes,
        privateNotes: this.privateNotes,
        releasedBy: this.releasedBy,
        requisitionedBy: this.requisitionedBy,
      });
      toasts.success('Subsolul documentului a fost modificat');
      this.isEditingFooter = false;
      await this.initialize();
    } catch (err: any) {
      toasts.error(err.response.data.errorMessage);
    }
  }

  onAddPublishedLine() {
    (<any>(this.$refs.editMaterialsRequisitionNotesLineModal)).showModal({
      lineNumber: null,
      inventoryId: this.inventory.inventoryId,
    }, true);
  }

  onEditLine(line) {
    (<any>(this.$refs.editMaterialsRequisitionNotesLineModal)).showModal(line);
  }

  async onAddPublishedLineEvent(itemLine) {
    console.log(itemLine);

    // TODO: build a mapping function as this code is also used in saveDraft
    const line = {
      lineTypeCode: 'MRN',
      inventoryId: itemLine.inventoryId,
      productId: itemLine.productId,
      productDescription: itemLine.productDescription,
      measurementUnit: itemLine.measurementUnit,
      requiredQuantity: itemLine.requiredQuantity,
      requisitionedQuantity: itemLine.requisitionedQuantity,
      specificUnitPrice: itemLine.specificUnitPrice,
      lineNotes: itemLine.lineNotes,
    };

    try {
      const response = camelcaseKeys(await addPublishedLine(this.materialsRequisitionNoteId, line));
      console.log(response);

      toasts.success('Linia a fost adăugată!');
      await this.initialize();
    } catch (err: any) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
    }
  }

  async onEditLineEvent(itemLine) {
    if (this.isPublished) {
      await this.onAddPublishedLineEvent(itemLine);
      return;
    }

    const item = this.items.find((e) => e.lineNumber === itemLine.lineNumber);
    if (!item) {
      this.showErrorsToast('Linia nu a putut fi actualizată', 'mrnDraftLineAddError');
      return;
    }

    // eslint-disable-next-line no-param-reassign
    item.productDescription = itemLine.productDescription;
    // eslint-disable-next-line no-param-reassign
    item.measurementUnit = itemLine.measurementUnit;
    // eslint-disable-next-line no-param-reassign
    item.requiredQuantity = itemLine.requiredQuantity;
    // eslint-disable-next-line no-param-reassign
    item.requisitionedQuantity = itemLine.requisitionedQuantity;
    // eslint-disable-next-line no-param-reassign
    item.specificUnitPrice = itemLine.specificUnitPrice;
    // eslint-disable-next-line no-param-reassign
    item.lineNotes = itemLine.lineNotes;
    // eslint-disable-next-line no-param-reassign
    item.vatPercentage = itemLine.vatPercentage;
    // eslint-disable-next-line no-param-reassign
    item.lineValue = itemLine.lineValue;
    // eslint-disable-next-line no-param-reassign
    item.lineVatValue = itemLine.lineVatValue;
  }

  async onRemovePublishedLine(line) {
    if (this.items.filter((l) => !l.isInventoryAllocation).length < 2) {
      this.$bvModal.msgBoxOk('Nu poate fi ștearsă ultima poziție dintr-un document procesat');
      return;
    }

    const confirmOutcome = await this.$bvModal.msgBoxConfirm(`Confirmi eliminarea poziției ${line.lineNumber}?`, {
      centered: true,
      okTitle: 'Da',
      cancelTitle: 'Nu',
    });
    if (!confirmOutcome) {
      return;
    }

    try {
      await deletePublishedLine(this.materialsRequisitionNoteId, line.materialsRequisitionNoteLineId);
      toasts.success('Linia a fost eliminată!');
    } catch (err: any) {
      toasts.error(err.response.data.errorMessage);
    }

    await this.initialize();
  }

  async onRemoveLine(line) {
    const confirmOutcome = await this.$bvModal.msgBoxConfirm('Confirmi eliminarea poziției?', {
      centered: true,
      okTitle: 'Da',
      cancelTitle: 'Nu',
    });
    if (!confirmOutcome) {
      return;
    }

    this.items = this.items.filter((i) => i !== line);
    this.items.forEach((item, index) => {
      // eslint-disable-next-line no-param-reassign
      item.lineNumber = index + 1;
    });

    this.isSaved = false;
  }

  async onAddLine() {
    if (!this.isDraft) {
      return;
    }

    const itemLine = {
      lineNumber: this.items.length + 1,
      inventoryId: this.inventory.inventoryId,
      productDescription: this.productDescription,
      productCode: this.selectedProduct?.label,
      productId: this.selectedProduct?.productId,
      lineNotes: this.lineNotes,
      measurementUnit: this.measurementUnit,
      requiredQuantity: this.requiredQuantity,
      requisitionedQuantity: this.requisitionedQuantity!,
      specificUnitPrice: this.selectedProductPrice?.pretGestiune,
    };

    const errors: Array<string> = [];

    // For now only allow inventoried entries
    if (!itemLine.productId) {
      errors.push('Produs neselectat');
    }
    if (!itemLine.measurementUnit) {
      errors.push('U.M. necompletată');
    }
    if (!itemLine.requiredQuantity) {
      errors.push('Cantitate necesară necompletată');
    }
    if (!itemLine.requisitionedQuantity) {
      errors.push('Cantitate consumată necompletată');
    }

    if (new Decimal(Decimal.sign(itemLine.requiredQuantity!)).minus(1).toNumber()
    !== new Decimal(Decimal.sign(itemLine.requisitionedQuantity!)).minus(1).toNumber()) {
      errors.push('Cantitatea necesară nu poate avea alt semn decât cantitatea consumată');
    }

    if (new Decimal(itemLine.requisitionedQuantity).lt(0) && !itemLine.specificUnitPrice) {
      errors.push('Prețul specific trebuie ales pentru pozițiile cu cantitate negativă');
    }

    if (errors.length) {
      this.showErrorsToast(errors, 'mrnDraftLineAddError');
      return;
    }

    let totalRequisitioned = new Decimal(itemLine.requisitionedQuantity);
    this.items.filter((e) => e.productId === itemLine.productId
      && e.selectedProductPrice === itemLine.specificUnitPrice)
      .forEach((item) => {
        const value = new Decimal(item.requisitionedQuantity);
        totalRequisitioned = totalRequisitioned.plus(value);
      });

    if (new Decimal(totalRequisitioned).gt(0)) {
      const availableStock = await getAvailableStock({
        lookupDate: format(parse(this.dataConsumValue, 'dd / MM / yyyy', new Date()), 'yyyy-MM-dd'),
        productId: itemLine.productId,
        inventoryId: this.inventory.inventoryId,
        unitPrice: this.selectedProductPrice?.pretGestiune,
      });

      if (new Decimal(totalRequisitioned).gt(availableStock.totalQuantity)) {
        this.$bvModal.msgBoxOk(`Stoc insuficient în gestiune (${this.formatNumber(availableStock.totalQuantity, 3)} unități) pentru produsul selectat!`);
        return;
      }
    }

    this.items.push(itemLine);
    this.isSaved = false;

    this.cleanLineInput();

    this.multiselectProductList = [];
  }

  getLatestUsedDocumentSeriesIndex(option) {
    return option.startIndex !== option.nextIndex
      ? option.nextIndex - 1
      : option.startIndex;
  }

  cleanLineInput() {
    this.selectedProduct = null;
    this.productDescription = null;
    this.lineNotes = null;
    this.measurementUnit = 'BUC';
    this.requiredQuantity = null;
    this.selectedProductPrice = null;
    this.requisitionedQuantity = null;
  }

  async initialize() {
    this.items = [];

    this.allInventoryOptions = camelcaseKeys(await getAvailableInventories());
    this.inventoryOptions = this.allInventoryOptions.filter((o) => o.isUserAssociated);

    if (this.inventoryOptions.length === 1) {
      [this.inventory] = this.inventoryOptions;
    }

    this.documentSeriesOptions = camelcaseKeys(await getAvailableSeries(this.dataConsumYear));

    let existingData: any|null = null;
    let copyData: any|null = null;
    let reverseNoteData: any|null = null;

    try {
      if (this.$route.params.id) {
        existingData = camelcaseKeys(await getDetails(this.$route.params.id));
      } else if (this.$route.query.copyFromId) {
        copyData = camelcaseKeys(await getDetails(this.$route.query.copyFromId));
      } else if (this.$route.query.reverseFromId) {
        reverseNoteData = camelcaseKeys(await getDetails(this.$route.query.reverseFromId));
      }
    } catch (err: any) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
      return;
    }

    if (!existingData) {
      //
      // Fresh object!
      //
      this.dataConsumValueDatepicker = format(new Date(), 'yyyy-MM-dd');

      this.releasedBy = this.currentUserFullName;

      if (this.documentSeriesOptions.length === 1) {
        [this.documentSeries] = this.documentSeriesOptions;
      }

      if (copyData) {
        this.releasedBy = copyData.releasedBy;
        this.requisitionedBy = copyData.requisitionedBy;
        this.inventory = copyData.inventory;
        this.documentSeries = copyData.documentSeries;

        copyData.lines.forEach((line) => {
          const itemLine = {
            lineNumber: line.lineNumber,
            inventoryName: line.inventoryName,
            inventoryCode: line.inventoryCode,
            inventoryId: line.inventoryId,
            productDescription: line.productDescription,
            productCode: line.productCode,
            productId: line.productId,
            lineNotes: line.lineNotes,
            measurementUnit: line.measurementUnit,
            requiredQuantity: line.requiredQuantity,
            requisitionedQuantity: line.requisitionedQuantity,
            specificUnitPrice: line.specificUnitPrice,
          };

          this.items.push(itemLine);
        });
      }

      if (reverseNoteData) {
        this.releasedBy = reverseNoteData.releasedBy;
        this.requisitionedBy = reverseNoteData.requisitionedBy;
        this.inventory = reverseNoteData.inventory;
        this.documentSeries = reverseNoteData.documentSeries;

        reverseNoteData.lines.forEach((line) => {
          const itemLine = {
            lineNumber: line.lineNumber,
            inventoryName: line.inventoryName,
            inventoryCode: line.inventoryCode,
            inventoryId: line.inventoryId,
            productDescription: line.productDescription,
            productCode: line.productCode,
            productId: line.productId,
            lineNotes: 'Restituire din Bon de consum '.concat(String(reverseNoteData.documentNumber)).concat(' din data de ').concat(format(parseISO(reverseNoteData.documentDate), 'dd/MM/yyyy')),
            measurementUnit: line.measurementUnit,
            // Received qty is reversed
            requiredQuantity: new Decimal(line.requiredQuantity).mul(-1).toNumber(),
            requisitionedQuantity: new Decimal(line.requisitionedQuantity).mul(-1).toNumber(),
            specificUnitPrice: line.specificUnitPrice,
          };

          this.items.push(itemLine);
        });
      }
    } else {
      //
      // We have a model ID!
      //
      this.statusCode = existingData.statusCode;
      this.materialsRequisitionNoteId = existingData.materialsRequisitionNoteId;
      this.documentNumber = existingData.documentNumber;
      this.dataConsumValueDatepicker = format(parseISO(existingData.documentDate), 'yyyy-MM-dd');

      this.dataConsumValue = format(parseISO(existingData.documentDate), 'dd /MM / yyyy');

      this.dataConsumYear = (new Date()).getFullYear();

      this.inventory = existingData.inventory;

      this.documentSeries = existingData.documentSeries;

      // Footer
      this.documentNotes = existingData.documentNotes;
      this.privateNotes = existingData.privateNotes;
      this.releasedBy = existingData.releasedBy;
      this.requisitionedBy = existingData.requisitionedBy;
      this.isLastDocument = existingData.isLastDocument;

      if (this.isPublished) {
        let currentLineNumber = 1;
        existingData.lines.forEach((line) => {
          line.inventoryAllocations.forEach((invAlloc, index) => {
            const itemLine = {
              materialsRequisitionNoteLineId: line.materialsRequisitionNoteLineId,
              lineNumber: (index === 0 ? currentLineNumber : null),
              inventoryName: line.inventoryName, // not shown
              inventoryCode: line.inventoryCode, // not shown
              inventoryId: line.inventoryId, // not shown
              productDescription: (index === 0 ? line.productDescription : null),
              productCode: (index === 0 ? line.productCode : null),
              productId: line.productId, // not shown
              lineNotes: (index === 0 ? line.lineNotes : null),
              measurementUnit: (index === 0 ? line.measurementUnit : null),
              requiredQuantity: (index === 0 ? line.requiredQuantity : null),
              inventoryUnitPrice: invAlloc.inventoryUnitPrice,
              inventoryRequisitionValue: invAlloc.inventoryRequisitionValue,
              requisitionedQuantity: invAlloc.requisitionedQuantity,
              specificUnitPrice: line.specificUnitPrice,
              isInventoryAllocation: index !== 0,
              // Allocation ID
              materialsRequisitionNoteLineInventoryAllocationId: invAlloc.materialsRequisitionNoteLineInventoryAllocationId,
              // Inventory account
              inventoryAccountId: invAlloc.inventoryAccountId,
              inventoryAccountNumber: invAlloc.inventoryAccountNumber,
              inventoryAccountName: invAlloc.inventoryAccountName,
            };

            currentLineNumber += 1;
            this.items.push(itemLine);
          });
        });
      } else {
        existingData.lines.forEach((line) => {
          const itemLine = {
            materialsRequisitionNoteLineId: line.materialsRequisitionNoteLineId,
            lineNumber: line.lineNumber,
            inventoryName: line.inventoryName,
            inventoryCode: line.inventoryCode,
            inventoryId: line.inventoryId,
            productDescription: line.productDescription,
            productCode: line.productCode,
            productId: line.productId,
            lineNotes: line.lineNotes,
            measurementUnit: line.measurementUnit,
            requiredQuantity: line.requiredQuantity,
            requisitionedQuantity: line.requisitionedQuantity,
            specificUnitPrice: line.specificUnitPrice,
          };

          this.items.push(itemLine);
        });
      }

      // We're clean
      this.isSaved = true;
    }

    this.loading = false;
  }

  async printDocument(numCopies = 1) {
    let data: any;

    try {
      data = await getPdf(this.materialsRequisitionNoteId, numCopies);
    } catch (err) {
      this.$bvModal.msgBoxOk('A intervenit o eroare la pregătirea documentului pentru imprimare! Încearcă din nou în câteva momente.');
      return;
    }

    if (!(data instanceof ArrayBuffer)) {
      this.$bvModal.msgBoxOk('A intervenit o eroare la pregătirea documentului pentru imprimare! Încearcă din nou în câteva momente.');
      return;
    }

    const base64Text = btoa(new Uint8Array(<any>data).reduce((d, byte) => d + String.fromCharCode(byte), ''));
    printJS({
      printable: base64Text,
      base64: true,
    });

    // Use printjs instead
    /*
    const blob = new Blob([data], { type: 'application/pdf' });

    if (this.printPreviewIframe) {
      this.printPreviewIframe.parentElement!.removeChild(this.printPreviewIframe);
    }

    this.printPreviewIframe = document.createElement('iframe'); // Create an IFrame.
    this.printPreviewIframe.style.visibility = 'hidden'; // Hide the frame.
    this.printPreviewIframe.src = URL.createObjectURL(blob);// Set source.
    (this.$refs.componentRoot as Element).appendChild(this.printPreviewIframe); // Add the frame to the component - this way it gets cleaned up when we leave

    this.printPreviewIframe.contentWindow!.focus(); // Set focus.
    this.printPreviewIframe.contentWindow!.print(); // Print it.
    */
  }

  async onPrintModal() {
    const numCopiesDecimal = new Decimal(this.printNumCopies);
    if (!numCopiesDecimal.isInteger()
    || numCopiesDecimal.lte(0)
    || numCopiesDecimal.gte(9)) {
      this.$bvModal.msgBoxOk('Numărul de copii care vor fi imprimate trebuie să fie între 1 și 9');
      return;
    }
    await this.printDocument(numCopiesDecimal.toNumber());
  }

  onPrint() {
    this.$bvModal.show('print-modal');
  }

  onReverseNote() {
    this.$router.push({ name: 'MaterialsRequisitionNotes.Create', query: { reverseFromId: `${this.materialsRequisitionNoteId}` } });
  }

  async onDeleteDraft() {
    if (!(await this.$bvModal.msgBoxConfirm('Dorești să ștergi acest document?', {
      title: 'Atenție',
      okTitle: 'Da',
      cancelTitle: 'Nu',
      centered: true,
    }))) {
      return;
    }
    try {
      if (this.materialsRequisitionNoteId) {
        await deleteDraft(this.materialsRequisitionNoteId);
      }

      toasts.success('Documentul a fost eliminat!');
      this.$router.replace({ name: 'MaterialsRequisitionNotes.Index' });
    } catch (err: any) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
    }
  }

  async onDeleteCanceled() {
    if (!(await this.$bvModal.msgBoxConfirm('Dorești să ștergi acest document?', {
      title: 'Atenție',
      okTitle: 'Da',
      cancelTitle: 'Nu',
      centered: true,
    }))) {
      return;
    }

    if (this.materialsRequisitionNoteId) {
      await deleteCanceled(this.materialsRequisitionNoteId);
    }

    toasts.success('Documentul a fost eliminat!');
    this.$router.replace({ name: 'MaterialsRequisitionNotes.Index' });
  }

  async onShowAccountingSyncData() {
    (<any>(this.$refs.accountingDataModal)).showModal(this.materialsRequisitionNoteId);
  }

  async onUnsyncAccounting() {
    if (await this.$bvModal.msgBoxConfirm('Confirmi că dorești să desincronizezi datele contabile?') === false) {
      return;
    }

    try {
      await unsyncAccounting(this.materialsRequisitionNoteId);
      toasts.success('Datele contabile au fost desincronizate');

      (<any>(this.$refs.accountingDataModal)).hideModal();
      await this.initialize();
    } catch (err: any) {
      toasts.error(err.response.data.errorMessage);
    }
  }

  async onSyncAccounting() {
    if (!this.materialsRequisitionNoteId || !this.isPublished) {
      toasts.error('Documentul nu este publicat!');
    }

    const syncAccountingItems = JSON.parse(JSON.stringify(this.items));

    (<any>(this.$refs.accountingSyncModal)).showModal(syncAccountingItems);
  }

  async onCancelDocument() {
    if (!(await this.$bvModal.msgBoxConfirm('Dorești să anulezi acest document?', {
      title: 'Atenție',
      okTitle: 'Da',
      cancelTitle: 'Nu',
      centered: true,
    }))) {
      return;
    }

    if (this.materialsRequisitionNoteId) {
      try {
        await cancelDocument(this.materialsRequisitionNoteId);

        toasts.success('Documentul a fost anulat!');
        this.$router.replace({ name: 'MaterialsRequisitionNotes.Index' });
      } catch (err: any) {
        toasts.error(err.response.data.errorMessage);
      }
    }
  }

  async onRestoreDocument() {
    if (!(await this.$bvModal.msgBoxConfirm('Dorești să restaurezi acest document?', {
      title: 'Atenție',
      okTitle: 'Da',
      cancelTitle: 'Nu',
      centered: true,
    }))) {
      return;
    }
    if (this.materialsRequisitionNoteId) {
      try {
        await restoreDocument(this.materialsRequisitionNoteId);
        toasts.success('Documentul a fost restaurat!');
        this.$router.replace({ name: 'MaterialsRequisitionNotes.Index' });
      } catch (err: any) {
        toasts.error(err.response.data.errorMessage);
      }
    }
  }

  async onShowDocumentLogs() {
    const logs = await getDocumentLogs(this.materialsRequisitionNoteId);
    this.documentLogs = logs.items?.reverse()?.map((e, index) => ({ ...e, index: index + 1 }));
    this.documentLogs = this.documentLogs.reverse();
    (<any>(this.$refs.documentLogEntriesModal)).showModal();
  }

  async onSaveDocument() {
    const errors: Array<string> = [];

    if (!this.items.length) {
      errors.push('Trebuie să adaugi măcar o linie');
    }

    if (!this.releasedBy) {
      errors.push('Nume gestionar necompletat');
    }

    if (!this.requisitionedBy) {
      errors.push('Nume primitor necompletat');
    }

    if (!this.documentSeries) {
      errors.push('Serie documente neselectată');
    }

    if (!this.items || !this.items.length) {
      errors.push('Documentul nu are linii');
    }

    if (errors.length) {
      this.showErrorsToast(errors, 'mrnDraftSaveError');
      return;
    }

    const draft = {
      materialsRequisitionNoteId: this.materialsRequisitionNoteId,
      inventoryId: this.inventory.inventoryId,

      documentDate: this.dataConsumValueDatepicker,
      documentSeriesId: this.documentSeries.documentSeriesId,

      privateNotes: this.privateNotes,
      documentNotes: this.documentNotes,
      releasedBy: this.releasedBy,
      requisitionedBy: this.requisitionedBy,

      // TODO: order by line number, perhaps? Just in case they're not in order
      lines: this.items.map((item) => ({
        materialsRequisitionNoteLineId: item.materialsRequisitionNoteLineId,
        lineTypeCode: 'MRN',
        inventoryId: item.inventoryId,
        productId: item.productId,
        productDescription: item.productDescription,
        measurementUnit: item.measurementUnit,
        requiredQuantity: item.requiredQuantity,
        requisitionedQuantity: item.requisitionedQuantity,
        specificUnitPrice: item.specificUnitPrice,
        lineNotes: item.lineNotes,
      })),
    };

    try {
      const response = camelcaseKeys(await saveDraft(draft));
      toasts.success('Documentul a fost salvat ca ciornă!');
      this.isSaved = true;
      this.materialsRequisitionNoteId = response.item1.materialsRequisitionNoteId;

      const compareLatestReponse = await compareLatestSeries(this.materialsRequisitionNoteId);
      if (compareLatestReponse.isCurrentDocumentDateEarlierThanLatest) {
        this.$bvModal.show('previous-series-warning-modal');
      } else {
        this.previewModalImageSrc = `/api/mrn/jpeg/${this.materialsRequisitionNoteId}?cb=${+new Date()}`;
        this.$bvModal.show('preview-modal');
      }
    } catch (err: any) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
    }
  }

  async onSyncAccountingModalOk(postData) {
    try {
      await syncAccounting(this.materialsRequisitionNoteId, postData);
      toasts.success('Sincronizarea contabilă a fost realizată!');

      (<any>(this.$refs.accountingSyncModal)).hideModal();
      await this.initialize();
    } catch (err) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
    }
  }

  async onPreviousSeriesWarningModalOk(evt) {
    evt.preventDefault();

    this.previewModalImageSrc = `/api/mrn/jpeg/${this.materialsRequisitionNoteId}?cb=${+new Date()}`;
    this.$bvModal.show('preview-modal');
  }

  // beforeRouteEnter(to, from, next) {

  // }

  // beforeRouteUpdate(to, from, next) {

  // }

  async beforeRouteLeave(to, from, next) {
    if (this.isSaved) {
      next();
      return;
    }

    try {
      if (await this.$bvModal.msgBoxConfirm('Informațiile nesalvate vor fi pierdute! Dorești să părăsești pagina curentă?', {
        title: 'Atenție',
        okTitle: 'Da',
        cancelTitle: 'Nu',
        centered: true,
      })) {
        next();
      }
      next(false);
    } catch (err) {
      next(false);
    }
  }

  async created() {
    await this.initialize();
  }
}
